import * as React from "react";
import {Component, MouseEvent} from "react";
import "./Tile.css";

export interface ITileProps {
    open: boolean;
    suspect: boolean;
    marked: boolean;
    mine: boolean;
    surrounded: number;

    onOpen: () => void;
    onHighlight: () => void;
    onExpand: () => void;
}

class Tile extends Component<ITileProps, any> {
    private timer: any;
    private touch: boolean = false;

    public render() {
        const classes = ["Tile"];

        let content = <span/>; // closed unknown
        if (this.props.open) {
            classes.push("Opened");
            if (this.props.mine) {
                content = <span className="fa fa-bomb"/>;
            } else {
                content = this.props.surrounded > 0 ? <span>{this.props.surrounded.toString()}</span> : <span/>;
            }
        } else {
            classes.push("Closed");
            if (this.props.marked) {
                content = <span className="fa fa-flag"/>;
            } else if (this.props.suspect) {
                content = <span className="fa fa-question"/>;
            } else {
                //
            }
        }

        return (
            <div
                className={classes.join(" ")}
                onClick={this.clickHandler}
                onContextMenu={this.contextMenuHandler}
                onTouchStart={this.touchStartHandler}
                onTouchEnd={this.touchEndHandler}
            >{content}</div>
        );
    }

    protected clickHandler = () => {
        this.props.onOpen();
    };

    protected contextMenuHandler = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!this.touch) {
            if (this.props.open) {
                this.props.onExpand();
            } else {
                this.props.onHighlight();
            }
        }
    };

    protected touchStartHandler = (event) => {
        if (!this.touch) {
            this.touch = true;
        }
        this.timer = setTimeout(() => {
            this.timer = undefined;
            if (this.props.open) {
                this.props.onExpand();
            } else {
                this.props.onHighlight();
            }
        }, 500);
    };

    protected touchEndHandler = (event) => {
        if (this.timer) {
            clearTimeout(this.timer);
        } else {
            event.preventDefault();
        }
    };
}

export default Tile;
