import * as React from 'react';

import "./Button.css";

interface IButtonProps {
    buttonType?: string,
    onClick: () => void,
    children?: React.ReactNode,
}


const button = (props: IButtonProps) => {
    const classes = ["Button", props.buttonType?props.buttonType:"Success"];
    return <button className={classes.join(' ')} onClick={props.onClick}>{props.children}</button>
};

export default button;