import * as React from "react";
import GameConfig from "../../components/GameConfig/GameConfig";
import Info from "../../components/Info/Info";
import Scoreboard from "../../components/Scoreboard/Scoreboard";
import IGame from "../../models/IGame";
import IGameConfig from "../../models/IGameConfig";
import GameEngine from "../../services/GameEngine";
import Modal from "../../UI/Modal/Modal";
import Navicon from "../../UI/Navicon/Navicon";
import {VERSION} from "../../Version";
import Grid from "../Board/Board";
import "./Game.css";

interface IGameControls {
    configuring: boolean,
}

interface IGameState {
    config: IGameConfig,
    game: IGame,
    controls: IGameControls,
}

class Game extends React.Component<any, IGameState> {
    private gameEngine: GameEngine;

    constructor(props: any) {
        super(props);

        // build configuration
        const config: IGameConfig = {
            rows: 9,
            columns: 9,
            mines: 10
        };

        // build game
        this.newGame(config);
        const game = this.gameEngine.game;

        // controls
        const controls: IGameControls = {
            configuring: false,
        };

        // build state
        this.state = {
            controls,
            config,
            game,
        }
    }

    public render(): React.ReactNode {
        return (
            <div className="Game">
                <div className="header">
                    <Scoreboard
                        total={this.state.game.total}
                        marked={this.state.game.marked}
                        started={this.state.game.started}
                        completed={this.state.game.completed}
                        failed={this.state.game.failed}

                        onReset={this.resetHandler}
                    />
                    <Navicon onClick={this.naviconHandler}/>
                </div>
                <Modal show={this.state.controls.configuring} onClose={this.configuringClose}>
                    {this.state.controls.configuring ?
                        <GameConfig config={this.state.config} onConfigure={this.configurationHandler}/> : null}
                </Modal>
                <Grid
                    rows={this.state.game.rows}
                    columns={this.state.game.columns}
                    mines={this.state.game.mines}
                    opened={this.state.game.opened}
                    marked={this.state.game.marked}
                    suspected={this.state.game.flagged}
                    surrounded={this.state.game.surrounded}
                    completed={this.state.game.completed}
                    failed={this.state.game.failed}

                    onTileOpen={this.handleTileOpen}
                    onTileHighlight={this.handleTileHighlight}
                    onTileExpand={this.handleTileExpand}
                />
                <Info version={VERSION} />
            </div>
        );
    }

    private handleTileOpen = (index: number) => {
        if (index < 0 || index >= this.state.game.size) {
            throw new Error("Illegal index: " + index);
        }

        if (!this.gameEngine.started) {
            this.gameEngine.start();
        }

        const needsUpdate = this.gameEngine.open(index);
        if (needsUpdate) {
            const game = this.gameEngine.game;
            this.setState({game});
        }
    };

    private handleTileHighlight = (index: number) => {
        if (index < 0 || index >= this.state.game.size) {
            throw new Error("Illegal index: " + index);
        }

        if (!this.gameEngine.started) {
            this.gameEngine.start();
        }

        const needsUpdate = this.gameEngine.toggle(index);
        if (needsUpdate) {
            const game = this.gameEngine.game;
            this.setState({game});
        }
    };

    private handleTileExpand = (index: number) => {
        if (index < 0 || index >= this.state.game.size) {
            throw new Error("Illegal index: " + index);
        }

        if (!this.gameEngine.started) {
            this.gameEngine.start();
        }

        const needsUpdate = this.gameEngine.clear(index);
        if (needsUpdate) {
            const game = this.gameEngine.game;
            this.setState({game});
        }
    };

    private resetHandler = () => {
        this.newGame(this.state.config);
        const game = this.gameEngine.game;
        this.setState({game});
    };

    private newGame = (config: IGameConfig) => {
        this.gameEngine = new GameEngine();
        this.gameEngine.generate(config.rows, config.columns, config.mines);
    };

    private naviconHandler = (): void => {
        this.setState(prevState => {
            return {
                ...prevState,
                controls: {
                    ...prevState.controls,
                    configuring: true
                }
            };
        });
    };

    private configuringClose = (): void => {
        this.setState(prevState => {
            return {
                ...prevState,
                controls: {
                    ...prevState.controls,
                    configuring: false
                }
            };
        });
    };

    private configurationHandler = (newConfig: IGameConfig): void => {
        this.setState(prevState => {
            this.newGame(newConfig);
            const game = this.gameEngine.game;

            const state = {
                ...prevState,
                config: newConfig,
                game,
                controls: {
                    configuring: false,
                    altClick: false,
                }
            };
            return state;
        });
    }
}

export default Game;