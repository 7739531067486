import * as React from "react";
import Digits from "../../UI/LED/LED";

import './Scoreboard.css'

interface IScoreboardProps {
    total: number;
    marked: boolean[];
    started: number | null;
    completed: boolean;
    failed: boolean;

    onReset: () => void,
}

interface IScoreboardState {
    started: number | null;
    elapsed: number;
    remaining: number;
}

const remainingOf = (total: number, marked: boolean[]): number => {
    return Math.min(total - marked.reduce((p, c) => p + (c ? 1 : 0), 0));
};


const elapsedSince = (started: number | null): number => {
    return started === null ? 0 : ((new Date().getTime() - started) / 1000);
};

class Scoreboard extends React.Component<IScoreboardProps, IScoreboardState> {
    protected static getDerivedStateFromProps(nextProps: IScoreboardProps, prevState: IScoreboardState): IScoreboardState | null {
        const nextRemaining = remainingOf(nextProps.total, nextProps.marked);
        const nextElapsed = elapsedSince(nextProps.started);
        return {
            started: nextProps.started,
            elapsed: nextElapsed,
            remaining: nextRemaining,
        };
    }

    private interval: ReturnType<typeof setInterval>;

    constructor(props: IScoreboardProps, context: any) {
        super(props, context);
        this.state = {
            started: 0,
            elapsed: 0,
            remaining: 0,
        };
    }

    public componentDidMount(): void {
        this.interval = setInterval(this.tick, 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    public componentDidUpdate(): void {
        if(this.props.completed || this.props.failed) {
            clearInterval(this.interval);
        }
    }

    public render() {
        return (
            <div className="Scoreboard">
                <div className="Remaining"><Digits src={this.state.remaining} /></div>
                <div className="Reset">
                    <div className="ResetButton" onClick={this.props.onReset}><span className="fa fa-smile" /></div>
                </div>
                <div className="Elapsed"><Digits src={this.state.elapsed} /></div>
            </div>
        );
    }

    protected tick = () => {
        this.setState(prevState => {
            const state: IScoreboardState = {...prevState};
            state.elapsed = elapsedSince(prevState.started);
            return state;
        });
    };
}

export default Scoreboard;
