export default interface IGameConfig {
    rows: number,
    columns: number,
    mines: number
}

export const LEVELS = {
    0: {rows: 9, columns: 9, mines: 10,},
    1: {rows: 16, columns: 16, mines: 40,},
    2: {rows: 30, columns: 16, mines: 100,},
    3: {rows: 30, columns: 24, mines: 200,},
}
