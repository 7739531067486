import * as _ from "lodash";
import IGame from "../models/IGame";
import {countSurrounding, openGroup, randomIntFromInterval, surrounding} from "./GameUtils";

let nextId = 1;
const hasCompleted = (game) => {
    const opened = game.opened.reduce((count, currentValue) => count + (currentValue ? 1 : 0), 0);
    const remaining = game.size - opened;
    if (remaining === game.total) {
        game.completed = true;
    }
};

export default class GameEngine {
    // @ts-ignore
    private id: number;
    private history: IGame[] = [];

    /**
     * Generated a new game and returns its id
     * @param {number} rows
     * @param {number} columns
     * @param {number} total
     * @returns {number}
     */
    public generate = (rows: number, columns: number, total: number): void => {
        const id = this.id = nextId++;
        const size: number = rows * columns;
        const mines = new Array(size).fill(false);
        const flagged = new Array(size).fill(false);
        const opened = new Array(size).fill(false);
        const marked = new Array(size).fill(false);
        const surrounded = new Array(size).fill(0);
        const started = null;
        const completed = false;
        const failed = false;
        const totalAllowed: number = Math.min(total, size);

        let count = 0;
        while (count < totalAllowed) {
            const index = randomIntFromInterval(0, size-1);
            if (!mines[index]) {
                mines[index] = true;
                count++;
            }
        }

        countSurrounding(mines, surrounded, rows, columns);

        const gameStatus = {
            id,
            rows,
            columns,
            size,
            total: totalAllowed,
            mines,
            flagged,
            opened,
            marked,
            surrounded,
            started,
            completed,
            failed,
        };

        this.history = [gameStatus];
    };

    /**
     * Returns a game by its id or throws an error if not found
     * @returns {IGame}
     */
    public get game(): IGame {
        const game = _.cloneDeep(this.current);
        if (!game.completed && !game.failed) {
            game.mines.fill(false);
        }
        return game;
    }

    /**
     * Only used for testing purposes
     * @returns {IGame}
     */
    public get current() {
        return this.history[this.history.length - 1];
    }

    public get started(): boolean {
        return this.current.started != null;
    }

    public get playing(): boolean {
        const game = this.current;
        return game.started != null && !game.failed && !game.completed
    }

    /**
     * Starts the game
     */
    public start = (): void => {
        if (this.started) {
            return;
        }

        const game = _.cloneDeep(this.current);
        game.started = new Date().getTime();
        this.history.push(game);
    };

    /**
     * Gives up the game
     */
    public giveup = (): void => {
        if (!this.playing) {
            return;
        }

        const game = _.cloneDeep(this.current);
        game.failed = true;
        this.history.push(game)
    };

    /**
     * Opens a cell with all assoiated consequences
     * @param {number} index
     * @returns true if state has changed
     */
    public open = (index: number): boolean => {
        if (!this.playing) {
            return false;
        }

        const game = _.cloneDeep(this.current);
        if (game.opened[index]) {
            return false;
        }

        game.opened[index] = true;
        game.marked[index] = false;
        game.flagged[index] = false;
        if (game.mines[index]) {
            game.failed = true;
            game.marked.forEach((m: boolean, i: number) => {
                if (!m) {
                    game.opened[i] = true;
                }
            });
        } else {
            openGroup(index, game.mines, game.surrounded, game.opened, game.rows, game.columns);
            hasCompleted(game);
        }

        this.history.push(game);
        return true;
    };


    /**
     * Marks a cell
     * @param {number} index
     */
    public flag = (index: number): void => {
        if (!this.playing) {
            return;
        }

        const game = _.cloneDeep(this.current);
        if (!game.flagged[index]) {
            game.flagged[index] = true;
            game.marked[index] = false;
            this.history.push(game);
        }
    };

    /**
     * Suspects a cell with all assoiated consequences
     * @param {number} index
     */
    public mark = (index: number): void => {
        if (!this.playing) {
            return;
        }

        const game = _.cloneDeep(this.current);
        if (!game.marked[index]) {
            game.marked[index] = true;
            game.flagged[index] = false;

            hasCompleted(game);

            this.history.push(game);
        }
    };

    /**
     * Toggle state of cell: marked -> flagged -> clear
     * @param {number} index
     * @returns {boolean}
     */
    public toggle = (index: number): boolean => {
        if (!this.playing) {
            return false;
        }

        const current = this.current;
        if (current.opened[index]) {
            return false;
        }

        const game = _.cloneDeep(current);
        if (!game.marked[index] && !game.flagged[index]) {
            game.marked[index] = true;
        } else if (game.marked[index]) {
            game.marked[index] = false;
            game.flagged[index] = true;
        } else {
            game.marked[index] = false;
            game.flagged[index] = false;
        }

        hasCompleted(game);

        this.history.push(game);
        return true;
    };

    public clear = (index: number): boolean => {
        if (!this.playing) {
            return false;
        }

        const current = this.current;
        if(!current.opened[index]) {
            return false;
        }

        const game = _.cloneDeep(current);
        surrounding(index, game.rows, game.columns).forEach(i => {
            if(!game.opened[i] && !game.flagged[i] && !game.marked[i]) {
                game.opened[i] = true;
                if(game.mines[i]) {
                    game.failed = true;
                } else {
                    openGroup(i, game.mines, game.surrounded, game.opened, game.rows, game.columns);
                }
            }
        });

        hasCompleted(game);
        this.history.push(game);
        return true;
    }
}