import * as React from 'react';
import Digit from "./Digit/Digit";
import "./LED.css";

interface IDigitsProps {
    src: number;
    digits?: number;
}

const LED = (props: IDigitsProps) => {
    const src = Math.floor(props.src);
    const n = props.digits ? props.digits : 3;
    const digits = Array(n)
        .fill(0)
        .map((v, i) => Math.floor( src / Math.pow(10, i)) % 10)
        .reverse();
    return (
        <span>{digits.map((v, i) => <Digit key={i} src={v} />)}</span>
    );
};

export default LED;
