import * as React from 'react';
import "./Navicon.css";

interface INaviconProps {
    onClick: () => void;
}

const navicon = (props: INaviconProps) => {
    return (
        <div className="navicon" onClick={props.onClick}>☰</div>
    );
};

export default navicon;
