import * as React from "react";
import "./Info.css";

interface IInfoProps {
    version: string;
}

const info = (props: IInfoProps) => {
    return (
        <>
            <div className="InfoVersion">
                {props.version}
            </div>
            < div
                className="InfoCopyright">
                © Ternary Games 2018-2022
            </div>
        </>
    );
};

export default info;
