import * as React from "react";
import {FunctionComponent, ReactNode} from "react";
import "./Board.css";

import Tile from "../../components/Tile/Tile";

export interface IBoardProps {
    rows: number;
    columns: number;

    mines: boolean[];
    opened: boolean[];
    marked: boolean[];
    suspected: boolean[];
    surrounded: number[];

    completed: boolean;
    failed: boolean;


    onTileOpen: (index: number) => void;
    onTileHighlight: (index: number) => void;
    onTileExpand: (index: number) => void;
}

const board: FunctionComponent<React.PropsWithChildren<IBoardProps>> = (props: IBoardProps) => {
    const rows: ReactNode[] = new Array(props.rows).fill(0).map((row, rowIndex) => {
        const cols: ReactNode[] = new Array(props.columns).fill(0).map((col, colIndex) => {
            const index = rowIndex * props.columns + colIndex;
            const openHandler = () => props.onTileOpen(index);
            const highlightHandler = () => props.onTileHighlight(index);
            const expandHandler = () => props.onTileExpand(index);

            return (
                <div key={index} className={"Column"}>
                    <Tile
                        open={props.opened[index]}
                        mine={props.mines[index]}
                        marked={props.marked[index]}
                        suspect={props.suspected[index]}
                        surrounded={props.surrounded[index]}
                        onOpen={openHandler}
                        onHighlight={highlightHandler}
                        onExpand={expandHandler}
                    />
                </div>
            );
        });
        return (
            <div key={rowIndex} className={"Row"}>
                {cols}
            </div>
        );
    });

    const boardClasses = ["Board"];
    if (props.completed) {
        boardClasses.push("Completed");
    } else if (props.failed) {
        boardClasses.push("Failed");
    }

    return (
        <div className={boardClasses.join(" ")}>
            {rows}
        </div>
    );
};

export default board;