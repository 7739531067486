/**
 * Converts a boolean array to a binary number
 * @param {boolean[]} a
 * @returns {number}
 */
export const arrayToBinary = (a: boolean[]): number => {
    const s = a.map(b => b ? 1 : 0).reverse().join("");
    return parseInt(s, 2);
};

/**
 * Converts a binary number to a boolean array
 * @param {number} size
 * @param {number} n
 * @returns {boolean[]}
 */
export const binaryToArray = (size: number, n: number): boolean[] => {
    const s = n.toString(2);
    const a:boolean[] = [];
    for (const c of s) {
        a.unshift(+c === 1);
    }
    while (a.length < size) {
        a.push(false);
    }
    return a;
};

export const randomIntFromInterval = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const surrounding = (index, rows, columns) => {
    const x = index % columns;
    const y = Math.floor(index / columns);

    const surroundingIndexes: number[] = [];
    for (let dy = -1; dy <= 1; dy++) {
        for (let dx = -1; dx <= 1; dx++) {
            if (dy === 0 && dx === 0) {
                continue;
            }

            if ((x + dx < 0) || (x + dx) >= columns || (y + dy < 0) || (y + dy) >= rows) {
                continue;
            }
            const i = x + dx + (y + dy) * columns;
            surroundingIndexes.push(i);
        }
    }
    return surroundingIndexes;
};

/**
 * Counts number of bombs surrounding each cell
 * @param {boolean[]} mines
 * @param {number[]} surrounded
 * @param {number} rows
 * @param {number} columns
 */
export const countSurrounding = (mines: boolean[], surrounded: number[], rows: number, columns: number): void => {
    mines.forEach((mine, index) => {
        if (!mine) {
            return;
        }
        const surroundingIndexes = surrounding(index, rows, columns);
        surroundingIndexes.forEach(i => {
            return surrounded[i]++;
        });
    });
};

/**
 * Recursively open all empty with no surrounding bombs cells
 * @param {number} i
 * @param {boolean[]} mines
 * @param {number[]} surrounded
 * @param {boolean[]} opened
 * @param {number} rows
 * @param {number} columns
 */
export const openGroup = (i: number, mines: boolean[], surrounded: number[], opened: boolean[], rows: number, columns: number): void => {
    if(surrounded[i] === 0) {
        const surroundingIndexes = surrounding(i, rows, columns);
        surroundingIndexes.forEach(ii => {
            if(!opened[ii]) {
                opened[ii] = true;
                openGroup(ii, mines, surrounded, opened, rows, columns);
            }
        });
    }
};