import * as React from 'react';
import Backdrop from '../Backdrop/Backdrop';
import './Modal.css';

interface IModalProps {
    show: boolean;
    children: any;
    onClose: () => void;
}

class Modal extends React.Component<IModalProps, any> {

    public shouldComponentUpdate ( nextProps, nextState ) {
        return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
    }

    public render () {
        return (
            <>
                <Backdrop show={this.props.show} clicked={this.props.onClose} />
                <div
                    className="Modal"
                    style={{
                        transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.props.show ? 1 : 0
                    }}>
                    {this.props.children}
                </div>
            </>
        )
    }
}

export default Modal;