import * as React from "react";
import "./Digit.css";

interface IDigitProps {
    src: number;
}

const digit = (props: IDigitProps) => {
    return (
        <img src={props.src + '.svg'} className="Digit" alt={'' + props.src}/>
    );
};

export default digit;
