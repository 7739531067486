import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import {MarkObj} from "rc-slider/es/Marks";
import * as React from 'react';
import {ReactNode} from 'react';
import IGameConfig, {LEVELS} from "../../models/IGameConfig";
import Button from "../../UI/Button/Button";
import "./GameConfig.css";

interface IGameConfigState {
    config: IGameConfig;
    preset?: number;
}

interface IGameConfigProps {
    config: IGameConfig;
    onConfigure: (config: IGameConfig) => void;
}

interface IField {
    min: number,
    max: number,
    step: number,
    marks?: Record<number, MarkObj>,
    name: string,
    label: string | ReactNode,
    onChangeHandler: (value: number) => void,
}

function presetMatchConfig(config: IGameConfig) {
    let preset: number = -1;
    for (const key in LEVELS) {
        if (LEVELS.hasOwnProperty(key)) {
            const level: IGameConfig = LEVELS[key];
            const a = config.rows === level.rows;
            const b = config.columns === level.columns;
            const c = config.mines === level.mines;
            const matched = a && b && c;
            if (matched) {
                preset = parseInt(key, 10);
                break;
            }
        }
    }
    return preset;
}

function arrayToMarks(a) {
    const o = {};
    a.forEach(v => {
        o[v] = {label: "" + v};
    })
    return o;
}

class GameConfig extends React.Component<IGameConfigProps, IGameConfigState> {
    public constructor(props: IGameConfigProps) {
        super(props);
        this.state = {
            config: props.config,
            preset: presetMatchConfig(props.config),
        };
    }

    public render() {
        const fields: IField[] = [
            {
                min: 9,
                max: 50,
                step: 1,
                marks: arrayToMarks([9, 16, 24, 30, 50]),
                name: "rows",
                label: <span className="fas fa-arrows-alt-h"/>,
                onChangeHandler: this.onRowsChangeHandler
            },
            {
                min: 9,
                max: 50,
                step: 1,
                marks: arrayToMarks([9, 16, 24, 30, 50]),
                name: "columns",
                label: <span className="fas fa-arrows-alt-v"/>,
                onChangeHandler: this.onColumnsChangeHandler
            },
            {
                min: 10,
                max: 300,
                marks: arrayToMarks([10, 40, 100, 200, 300]),
                step: 1,
                name: "mines",
                label: <span className='fa fa-bomb'/>,
                onChangeHandler: this.onMinesChangeHandler
            },
        ];

        return (
            <div className="GameConfig" style={{
                //
            }}>
                {fields.map(field => (
                    <div className="field" key={field.name}>
                        <table>
                            <tr>
                                <td className="Label">
                                    <label>{field.label}</label>
                                </td>
                                <td className="Slider">
                                    <Slider
                                        min={field.min}
                                        max={field.max}
                                        step={field.step}
                                        marks={field.marks}
                                        value={this.state.config[field.name]}
                                        onChange={field.onChangeHandler}/>
                                </td>
                            </tr>
                        </table>
                    </div>
                ))}
                <div className="field">
                    <Slider
                        min={-1}
                        max={3}
                        step={1}
                        marks={{
                            "-1": "✎",
                            0: "🌤",
                            1: "🌥",
                            2: "🌦",
                            3: "⛈",
                        }}
                        value={this.state.preset}
                        onChange={this.onPresetHandler}
                    />
                </div>

                <div className="actions">
                    <Button onClick={this.configureHandler}>Apply</Button>
                </div>
            </div>
        );
    }

    private onRowsChangeHandler = (value: number) => this.onChangeHandler('rows', value);
    private onColumnsChangeHandler = (value: number) => this.onChangeHandler('columns', value);
    private onMinesChangeHandler = (value: number) => this.onChangeHandler('mines', value);
    private onChangeHandler = (field: string, value: number) => {
        this.setState(prevState => {
            const config = {...prevState.config};
            config[field] = value;
            const preset = presetMatchConfig(config);

            return {
                preset,
                config
            };
        });
    };

    private onPresetHandler = (preset: number) => {
        this.setState(prevState => {
            if (preset < 0) {
                return prevState;
            }

            const state = {
                config: LEVELS[preset],
                preset,
            };
            return state;
        });
    };

    private configureHandler = () => {
        this.props.onConfigure(this.state.config);
    };
}

export default GameConfig;
